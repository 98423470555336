import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import { IlcollectionLogo, LogoTop, LogoBottom } from "../images/logos";
import { motion, useInView, useAnimation } from "framer-motion";

const AboutPage = (props) => {
  const aboutText = props.data.strapiAbout.aboutText.data.aboutText;

  // Animation inView
  // IN-VIEW SHOW
  const pageContainerRef = React.useRef(null);
  const isInView = useInView(pageContainerRef, { once: false, amount: 0.1 });
  const inViewControls = useAnimation();
  const inViewVariants = {
    visible: {
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: 10,
    },
  };
  React.useEffect(() => {
    if (isInView) {
      inViewControls.start("visible");
    } else if (!isInView) {
      inViewControls.start("hidden");
    }
  }, [inViewControls, isInView]);

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <motion.div
        ref={pageContainerRef}
        animate={inViewControls}
        initial={"hidden"}
        variants={inViewVariants}
        transition={{ type: "easeOut", duration: 1 }}
      >
        {/* <IlcollectionLogo classes={"h-[50%] w-auto"} /> */}

        <div className="h-full relative">
          <motion.div
            className="absolute top-0"
            animate={{
              opacity: [1, 0, 1],
            }}
            transition={{
              duration: 10,
              ease: "easeOut",
              times: [0, 0.5, 1],
              repeat: Infinity,
              repeatDelay: 3,
            }}
          >
            <LogoTop classes={`w-screen max-w-full`} />
          </motion.div>
          <motion.div
            className=""
            animate={{
              opacity: [0, 1, 0],
            }}
            transition={{
              duration: 10,
              ease: "easeOut",
              times: [0, 0.5, 1],
              repeat: Infinity,
              repeatDelay: 3,
            }}
          >
            <LogoBottom classes={`w-screen max-w-full`} />
          </motion.div>
        </div>
      </motion.div>
      <div
        className="mt-xxl xs:w-full lg:w-full xl:w-3/4 text-box xs:columns-1 lg:columns-2 gap-x-xl"
        dangerouslySetInnerHTML={{
          __html: aboutText,
        }}
      />
    </Layout>
  );
};
export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
    strapiAbout {
      aboutText {
        data {
          aboutText
        }
      }
    }
  }
`;

export default AboutPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Home"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
